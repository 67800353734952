

#content-preview .item-gallery-header {
    margin-bottom 5px !important
}

#content-preview .item-gallery-header .label-container {
    //justify-content flex-start !important
}

#content-preview .item-gallery-header .label {
    font-weight 700
    font-size 22px
    color rgba(0, 0, 0, 0.74);
}
