


.draft-view .popover-inner {
    width: auto !important
    padding: 0 !important
    background-color: transparent !important
}




.draft-view {
     font-family: Roboto, sans-serif, "Helvetica", "Arial"
     font-size: 20px
     font-weight: 400;
     line-height: 1.8;
     color rgb(74, 74, 74)
}

.draft-view .view_graf--layoutOutsetLeft {
    width 50%
}

.draft-view h1 {
    font-size 40px
    color rgba(0,0,0,.74)
    font-family: Roboto, sans-serif, "Helvetica", "Arial"
    font-weight 700
}

.draft-view h2 {
    font-size 26px
    color rgba(0,0,0,.74)
}

@media screen and (max-width: 767px) {
    .draft-view {
         font-size: 18px
    }

    .draft-view .view_graf--layoutOutsetLeft {
        width: 100%;
        float: none;
        margin: 0 auto;
    }

    .draft-view .view_graf--layoutFillWidth {
        margin-left: 0px;
        margin-right: 0px;
    }

    .draft-view .view_graf--layoutFillWidth > figure {
        margin-left: -15px;
        margin-right: -15px;
    }
}
