//gen styles

.draft-view figure {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 40px;
    margin-inline-end: 40px;
}


.view_graf--h2,
.view_graf--h3,
.view_graf--h4,
.view_graf--h5,
.view_graf--h6,
.view_graf--h7,
.postList,
.view_graf--hr,
.draft-view .view_graf--figure,
.view_graf--blockquote,
.view_graf--pullquote,
.view_graf--p,
.view_graf--pre {
  margin: 0;
  //position:relative;
}

.view_graf--code {
  background: transparent;
  position:relative;
  overflow: visible;
  .dante-code-syntax{
    position: absolute;
    top: -17px;
    right: -18px;
    width: 165px;
  }
}

.postList {
  margin-bottom: 30px;
}

.view_graf--p,
.view_graf--blockquote,
.view_graf--pullquote {
  margin-bottom: 30px;
}

.view_graf--code {
  line-height: 1em;
}

.view_graf--p.dante--spinner{
  position:relative;
}

.view_graf--hr {
  hr{
    border: 1px solid #ccc;
    margin: 26px;
  }
}

.view_graf--h2 {
  font-family: Roboto, "Helvetica", "Arial", sans-serif;
  font-size: 3.6em;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.04em;
  line-height: 1;
  margin-bottom: .4em;
  margin-left: -3px;
  margin-top: 40px;
  padding-top: 0;
}
.view_graf--h3 {
  font-family: Roboto, "Helvetica", "Arial", sans-serif;
  letter-spacing: -0.02em;
  font-weight: 700;
  font-style: normal;
  font-size: 2.1em;
  margin-left: -1.8px;
  line-height: 1.2;
  margin-top: 40px;
  margin-bottom: .7em;
}
.public-DraftStyleDefault-pre{
  overflow: inherit;
}
.view_graf--h4 {
  font-family: Roboto, "Helvetica", "Arial", sans-serif;
  letter-spacing: -0.02em;
  font-weight: 300;
  font-style: normal;
  font-size: 1.5em;
  margin-left: -1.5px;
  line-height: 1.2;
  color: rgba(0,0,0,0.44);
  margin-top: 40px;
  margin-bottom: .6em;
}

.section--first .view_graf--h2.view_graf--first,
.section--first .view_graf--h3.view_graf--first,
.section--first .view_graf--h4.view_graf--first {
  margin-top: 0;
  padding-top: 0;
}

.view_graf--h2 + .view_graf--h2 {
  margin-top: -8px;
}

.view_graf--h2 + .view_graf--h3,
.view_graf--h2 + .view_graf--h4 {
  margin-top: -6px;
}

.view_graf--h3 + .view_graf--h2,
.view_graf--h4 + .view_graf--h2 {
  margin-top: 2px;
}

.view_graf--h3 + .view_graf--h4,
.view_graf--h4 + .view_graf--h3 {
  margin-top: -2px;
}

.view_graf--h2 + .postList,
.view_graf--h3 + .postList,
.view_graf--h4 + .postList {
  margin-top: 10px;
}

.view_graf--h2 + .view_graf--p.view_graf--empty,
.view_graf--h3 + .view_graf--p.view_graf--empty,
.view_graf--h4 + .view_graf--p.view_graf--empty {
  margin-bottom: -7px;
  margin-top: -7px;
}

.view_graf--h2 + .view_graf--p.view_graf--empty + .view_graf--h2,
.view_graf--h3 + .view_graf--p.view_graf--empty + .view_graf--h2,
.view_graf--h4 + .view_graf--p.view_graf--empty + .view_graf--h2 {
  margin-top: -5px;
}

.view_graf--h2 + .view_graf--p.view_graf--empty + .view_graf--h3,
.view_graf--h3 + .view_graf--p.view_graf--empty + .view_graf--h3,
.view_graf--h4 + .view_graf--p.view_graf--empty + .view_graf--h3,
.view_graf--h2 + .view_graf--p.view_graf--empty + .view_graf--h4,
.view_graf--h3 + .view_graf--p.view_graf--empty + .view_graf--h4,
.view_graf--h4 + .view_graf--p.view_graf--empty + .view_graf--h4 {
  margin-top: -8px;
}


.view_graf--blockquote, blockquote {
  font-family: Roboto, "Helvetica", "Arial", sans-serif
  border-left: 3px solid rgba(0, 0, 0, .8);

  font-style: italic;
  font-weight: 400;
  letter-spacing: 0.16px;
  letter-spacing: 0.02rem;
  margin-left: -17px;
  padding-left: 15px;
  margin-bottom: 25px;
  font-size: 1.2em;
  line-height: 1.9em;
  margin-top: 20px;

}
.view_graf--blockquote + .view_graf--blockquote {
  margin-top: -30px;
  padding-top: 30px;
}

.view_graf--pullquote {
  line-height: 1.4;
  text-align: center;
  font-size: 3.2em;
  margin: 48px -160px;
  border: none;
  padding: 0;
  font-family: $dante-font-family-serif;
  letter-spacing: 0.01rem;
  font-weight: 400;
  font-style: italic;
  -webkit-transition: margin 100ms;
  transition: margin 100ms;
}

.view_graf--pre, .public-DraftStyleDefault-pre {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0.05);
  font-family: $dante-font-family-mono;
  font-size: 16px;
  margin-bottom: 20px;
  padding: 20px;
  white-space: pre-wrap;
}
.view_graf--pre + .view_graf--pre {
  margin-top: -20px;
}



.draft-view .view_graf--figure {
  & {
    box-sizing: border-box;
    clear: both;
    margin-bottom: 30px;
    outline: medium none;
    position: relative;
  }
  &.is-mediaFocused .view_graf-image,
  &.is-mediaFocused iframe {
    box-shadow: 0 0 0 3px #57ad68;
  }
}

.view_graf--mixtapeEmbed {
  a {
    text-decoration: none;
  }
  &.is-mediaFocused {
    box-shadow: 0 0 0 1px #57ad68;
  }

  .view_graf--media-embed-close{
    position: absolute;
    top: 1px;
    display: inline-block;
    font-size: 2em;
    width: 20px;
    right: 10px;
    text-shadow: 0px 0px 0px white;
  }
}



.view_graf--h4 + .view_graf--figure,
.view_graf--h3 + .view_graf--figure,
.view_graf--h2 + .view_graf--figure {
  margin-top: 15px;
}

.view_graf--first {
  margin-top: 0;
  padding-top: 0;
}

/*.view_graf--empty {
  margin-bottom: -7px;
  margin-top: -7px;
}*/

p[data-align="center"],
.view_graf--h2[data-align="center"],
.view_graf--h3[data-align="center"],
.view_graf--h4[data-align="center"],
.view_graf--blockquote[data-align="center"] {
  text-align: center;
}

.markup--anchor,
.view_graf--sectionCaption {
    cursor: text;
}
.markup--anchor {
  text-decoration: underline;
  color: inherit;
}

@media (max-width: 500px) {

  .iraf--h2 {
    font-size: 2.6em;
  }
  .view_graf--h3 {
    font-size: 1.6em;
  }
  .view_graf--h4 {
    font-size: 1.4em;
  }

}

.view_graf--divider span{
  text-align: center;
  width: 100%;
  display: block;
}

.view_graf--divider span:before {
  line-height: 1;
  user-select: none;
  font-weight: 400;
  font-size: 25px;
  letter-spacing: 18px;
  content: "...";
  display: inline-block;
  margin-left: .6em;
  position: relative;
  color: #757575;
  top: -3px;
}
