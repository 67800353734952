div[contenteditable="false"] {
  a.markup--anchor {
    cursor: pointer;
  }
}

figcaption .public-DraftStyleDefault-block {
    text-align: center;
}

.aspectRatioPlaceholder {
  .image-upoader-loader{
    position: absolute;
    bottom: 0px;
    left: 0%;
    background-color: #fff;
    width: 100%;
    /* height: 3px; */
    text-align: center;
    top: 0px;
    vertical-align: text-bottom;
    opacity: 0.7;
    p{
      line-height: 5px;
      /* font-weight: 700; */
      /* text-transform: uppercase; */
      font-size: 14px;
      margin-top: 49%;
    }
  }
}

a[rel=token], .markup--query {
    color: #00ab6b;
    text-decoration: none;
    background-image: none!important;
}


.typeahead--mention {
    padding-top: 10px;
}
.typeahead .popover-inner {
    padding: 0;
    overflow: hidden;
    min-width: 100px;
}

.typeahead .typeahead-item:first-child {
  padding-top: 7px;
}

.typeahead--mention .typeahead-item {
  padding: 6px 15px;
  font-size: 13px;
}

.typeahead-item.is-active, .typeahead-item:hover  {
  background: #02b875;
  color: #fff;
}
.typeahead .typeahead-item {
  cursor: pointer;
  padding: 5px 10px;
  line-height: 2;
  font-size: 12px;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.typeahead .dante-avatar {
  //float: left;
  margin: 0 6px 0 -2px;
}

.avatar-image--icon {
  width: 32px;
  height: 32px;
}
.avatar-image {
  display: inline-block;
  vertical-align: middle;
  border-radius: 100%;
}

.typeahead-item .avatar-image {
  width: 32px;
  height: 32px;
}

.typeahead--mention .popover-arrow {
    display: none;
}

.markup--user {
  color: #00ab6b;
  text-decoration: none;
}



//popover card styles

.popover--card {
  .popover-arrow{
    top: -14px;
  }

  .popover--bottom .popover-arrow:after {
      transform: rotate(45deg) translate(6px,6px);
      box-shadow: -1px -1px 1px -1px rgba(0,0,0,.44);
  }

  .popover-arrow:after {
      content: '';
      display: block;
      width: 14px;
      height: 14px;
      background: #fff;
  }
}

.popover--animated.is-active {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s,opacity .2s 0s;
}

.popover.is-active {
  visibility: visible;
}

.popover--animated {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear .2s,opacity .2s 0s;
}

.popoverCard {
  text-align: left;
}

.popoverCard-meta {
  width: 192px;
}
.u-floatLeft {
  float: left!important;
}

.u-floatRight {
  float: right!important;
}

.popoverCard-title {
  font-size: 18px;
  margin-bottom: 5px;
  margin-top: 0px;
}

.link {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

.popoverCard-description {
  overflow: hidden;
  color: rgba(0,0,0,.44);
}

.u-clearfix:after {
    clear: both;
}
.u-clearfix:after, .u-clearfix:before {
    display: table;
    content: " ";
}

.dante-avatar {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: normal;
}

.avatar-image--small {
  width: 60px;
  height: 60px;
}
.avatar-image {
  display: inline-block;
  vertical-align: middle;
  border-radius: 100%;
}

.popoverCard-actions {
  border-top: solid 1px rgba(0,0,0,.15);
  margin-top: 10px;
  padding-top: 10px;
}

.popoverCard-stats {
  font-size: 14px;
  line-height: 36px;
}

.popoverCard-stat {
  margin-right: 10px;
  color: rgba(0,0,0,.44);
}

.popoverCard-count {
  padding-left: 5px;
  color: rgba(0,0,0,.6);
}

// popover-align




// uploader tooltip

.draft-view .view_graf--layoutOutsetLeft {
    margin-left: -160px;
}

.draft-view .view_graf--layoutFillWidth {
    margin-left: -200px;
    margin-right: -200px;
}

.draft-view .view_graf--layoutOutsetLeft {
    width: 75%;
}
.draft-view .view_graf--layoutInsetLeft, .draft-view .view_graf--layoutOutsetLeft {
    float: left;
    margin-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
}

// .view_graf--layoutOutsetLeft

.is-embedable {
  .public-DraftEditorPlaceholder-inner{
    //font-size: 2em;
  }

  .public-DraftStyleDefault-block{
    //font-size: 2em;
  }
}

.public-DraftEditorPlaceholder-inner{
  font-size: initial;
}
