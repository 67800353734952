


.product-table .MuiTableBody-root .MuiTableCell-root {
    padding-top: 3px !important
    padding-bottom: 3px !important
}

.product-table .MuiTablePagination-caption {
    display: none;
}

.product-table .MuiTableCell-stickyHeader {
    background-color: #fff;
}
