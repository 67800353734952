

.product-gallery-info {
    font-size 15px
    line-height 24px
    margin-bottom 15px
    padding-left 20px
    padding-right 20px
    padding-top 15px
    padding-bottom 15px
    color #4a4a4a
}

.product-gallery-info h1 {
    color: #292929;
    font-size 20px
    margin-bottom 5px
}


@media screen and (max-width: 767px) {
    .product-gallery-info {
         line-height 23px
         margin-left -5px !important
         margin-right -5px !important
         margin-top 0px !important
         margin-bottom 12px !important
    }
}
