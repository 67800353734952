.szh-menu-button{padding:0;background:rgba(0,0,0,0);border:none}

.editor-dialog {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.editor-dialog .header {
  width: 100%;
  height: 50px;
  min-height: 50px;
  padding: 0 20px;
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid #eee;
  box-sizing: border-box;
}
.editor-dialog .body {
  max-width: 740px;
  width: 100%;
  padding: 20px;
  font-size: 21px;
  box-sizing: border-box;
}
.dialog-fake.overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  position: fixed;
  touch-action: none;
  background-color: rgba(0,0,0,0.5);
  -webkit-tap-highlight-color: transparent;
}


.image-widget-item {
  background: no-repeat 50%;
  background-size: cover;
  border-radius: 3px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
  overflow: hidden;
  margin: 0;
  vertical-align: top;
  display: inline-block;
}
.cart-content {
  flex: 1 1 auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.cart-actions {
  flex: 0 0 auto;
}
.button-checkout {
  border-radius: 10px 0 0 10px !important;
  background-color: #c1caec !important;
  opacity: 0.8 !important;
}
.button-checkout:hover,
.button-checkout:active,
.button-checkout:focus {
  opacity: 1 !important;
}
@media screen and (max-width: 767px) {
  .cart-content .name h2 {
    font-size: 18px;
  }
}

.cart-widget {
  background-color: #fff !important;
  border-left: 1px solid #eee !important;
}

.product-gallery-item h2 {
  font-weight: 300;
  font-size: 14px;
}

.ant-pagination {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0,0,0,0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum';
}
.ant-pagination .item {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  margin-right: 8px;
  font-family: Arial;
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  outline: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-pagination .item a {
  display: block;
  padding: 0 6px;
  color: rgba(0,0,0,0.65);
  -webkit-transition: none;
  transition: none;
}
.ant-pagination .item.selected {
  font-weight: 500;
  background: #fff;
  border-color: #3f51b5;
  color: #3f51b5;
}
.ant-pagination .item:focus,
.ant-pagination .item:hover {
  border-color: #3f51b5;
  color: #3f51b5;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}


.product-grid-tmall .item {
  width: 20%;
  min-width: 20%;
  padding: 8px;
}
.product-grid-tmall.with-filter .item {
  width: 25%;
  min-width: 25%;
}
@media only screen and (max-width: 1000px) {
  .product-grid-tmall .item {
    width: 25%;
    min-width: 25%;
  }
  .product-grid-tmall.with-filter .item {
    width: 33.33333%;
    min-width: 33.33333%;
  }
}
.product-mobile-grid {
  margin-left: 5px;
  margin-right: 5px;
  min-height: 70px;
  transition: 0.3s;
  opacity: 1;
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}
.product-mobile-grid .item {
  width: 50%;
}
/*

.product-grid
  display: grid;
  grid-template-columns: 200px 200px 200px 200px 200px 200px
  justify-content: space-between;
  row-gap: 25px;
  column-gap: 25px;




@media only screen and (max-width: 1390px) {
  .product-grid {
    grid-template-columns: 200px 200px 200px 200px 200px
  }
}

@media only screen and (max-width: 1130px) {
  .product-grid {
    grid-template-columns: 200px 200px 200px 200px 200px
  }
}

@media only screen and (max-width: 910px) {
  .product-grid {
    grid-template-columns: 200px 200px 200px
  }
}



@media only screen and (max-width: 768px) {
  .product-grid {
    grid-template-columns: 100%
  }
  .product-item .name {
    font-size 16px !important
  }
}

*/

.product-list-view-item h2 {
  font-weight: 300;
  font-size: 14px;
}


.product-table-item h2 {
  font-weight: 300;
  font-size: 14px;
}

.product-table .MuiTableBody-root .MuiTableCell-root {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}
.product-table .MuiTablePagination-caption {
  display: none;
}
.product-table .MuiTableCell-stickyHeader {
  background-color: #fff;
}

.searchbar-widget {
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.searchbar-widget:hover {
  background-color: rgba(0,0,0,0.06) !important;
}
.searchbar-widget .MuiInputBase-input {
  padding: 0;
}
.autocomplete-dropdown-cancel-area {
  z-index: -1;
  position: fixed;
  right: 0px;
  bottom: 0px;
  top: 0px;
  left: 0px;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  touch-action: none;
}


.facet-filter-widget .MuiSvgIcon-root {
  width: 16px;
  height: 16px;
  opacity: 0.6;
}

.filter-selected-chip .MuiSvgIcon-root {
  width: 16px;
  height: 16px;
  opacity: 0.6;
}



.filter-mobile-widget .MuiDrawer-paper {
  width: 80%;
  font-size: 16px;
}
.filter-mobile-widget .facet-filter-widget .MuiSvgIcon-root {
  width: 24px;
  height: 24px;
  opacity: 0.6;
}
.filter-mobile-widget .link {
  font-size: 14px !important;
}

.category-dropdown-widget {
  display: block;
  top: 45px;
  left: 0;
}
.category-dropdown-widget .subcategory {
  display: none;
  left: 238px;
}
.category-dropdown-widget .category:hover .subcategory {
  display: flex;
  top: -21px;
  min-height: calc(100% + 42px);
}
.category-dropdown-cancel-area {
  z-index: -1;
  position: fixed;
  right: 0px;
  bottom: 0px;
  top: 0px;
  left: 0px;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  touch-action: none;
}



.filter-mobile-widget .item-list {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
}
.filter-mobile-widget .item {
  min-height: 44px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-bottom: 1px solid #eee;
  color: rgba(0,0,0,0.54);
}
.filter-mobile-widget .item-list.child .item {
  color: rgba(0,0,0,0.84);
}
.filter-mobile-widget .item .MuiListItemIcon-root {
  min-width: 50px;
}

.filter-mobile-widget .action {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 80%;
  z-index: 100;
  padding: 8px 16px;
}
.filter-mobile-widget .MuiButton-containedPrimary {
  background-color: #606fbb;
}


.product-gallery-info {
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 15px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  color: #4a4a4a;
}
.product-gallery-info h1 {
  color: #292929;
  font-size: 20px;
  margin-bottom: 5px;
}
@media screen and (max-width: 767px) {
  .product-gallery-info {
    line-height: 23px;
    margin-left: -5px !important;
    margin-right: -5px !important;
    margin-top: 0px !important;
    margin-bottom: 12px !important;
  }
}

.product-gallery-category {
  display: flex;
  flex-wrap: wrap;
  font-size: 15px;
  margin-bottom: 15px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 15px;
  padding-bottom: 7px;
  color: #4a4a4a;
}
.product-gallery-category .item {
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 2px 10px;
  background: #eee;
  border-radius: 3px;
  text-decoration: none;
  font-weight: 400;
  color: #707070;
  font-size: 13px;
  white-space: nowrap;
  opacity: 0.8;
  cursor: pointer;
}
.product-gallery-category .item:hover {
  opacity: 1;
}
.product-gallery-category .item.selected {
  background: #4e579c;
  color: #ececec;
}
.product-gallery-category-mobile {
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 0px;
  margin-bottom: 18px;
  overflow-x: auto;
}
.product-gallery-category-mobile > div {
  padding-left: 15px;
}
.product-gallery-category-mobile .item {
  margin-right: 8px;
  padding: 10px 10px;
  background: #fff;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 400;
  color: #707070;
  font-size: 13px;
  white-space: nowrap;
}
.product-gallery-category-mobile .item.selected {
  background: #8890cc;
  color: #fff;
}
.category-menu-block {
  height: 55px;
  border-bottom: 1px solid #f7f7f7;
  color: rgba(0,0,0,0.54);
}
.category-menu-block .item {
  height: 52px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
}
.category-menu-block .item-container:hover .item {
  border-bottom: 2px solid #7177a7;
}
.category-menu-block .subitem {
  display: none;
  left: -6px;
  top: 52px;
  padding-top: 5px !important;
}
.category-menu-block .subitem > div {
  box-shadow: 0 3px 5px 0 rgba(51,51,51,0.3);
}
.category-menu-block .item-container:hover .subitem {
  display: flex;
}
@media screen and (max-width: 767px) {
  .product-gallery-category {
    margin-left: -5px !important;
    margin-right: -5px !important;
    margin-top: 0px !important;
    margin-bottom: 12px !important;
  }
}

.draft-view figure {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
}
.view_graf--h2,
.view_graf--h3,
.view_graf--h4,
.view_graf--h5,
.view_graf--h6,
.view_graf--h7,
.postList,
.view_graf--hr,
.draft-view .view_graf--figure,
.view_graf--blockquote,
.view_graf--pullquote,
.view_graf--p,
.view_graf--pre {
  margin: 0;
}
.view_graf--code {
  background: transparent;
  position: relative;
  overflow: visible;
}
.view_graf--code .dante-code-syntax {
  position: absolute;
  top: -17px;
  right: -18px;
  width: 165px;
}
.postList {
  margin-bottom: 30px;
}
.view_graf--p,
.view_graf--blockquote,
.view_graf--pullquote {
  margin-bottom: 30px;
}
.view_graf--code {
  line-height: 1em;
}
.view_graf--p.dante--spinner {
  position: relative;
}
.view_graf--hr hr {
  border: 1px solid #ccc;
  margin: 26px;
}
.view_graf--h2 {
  font-family: Roboto, "Helvetica", "Arial", sans-serif;
  font-size: 3.6em;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.04em;
  line-height: 1;
  margin-bottom: 0.4em;
  margin-left: -3px;
  margin-top: 40px;
  padding-top: 0;
}
.view_graf--h3 {
  font-family: Roboto, "Helvetica", "Arial", sans-serif;
  letter-spacing: -0.02em;
  font-weight: 700;
  font-style: normal;
  font-size: 2.1em;
  margin-left: -1.8px;
  line-height: 1.2;
  margin-top: 40px;
  margin-bottom: 0.7em;
}
.public-DraftStyleDefault-pre {
  overflow: inherit;
}
.view_graf--h4 {
  font-family: Roboto, "Helvetica", "Arial", sans-serif;
  letter-spacing: -0.02em;
  font-weight: 300;
  font-style: normal;
  font-size: 1.5em;
  margin-left: -1.5px;
  line-height: 1.2;
  color: rgba(0,0,0,0.44);
  margin-top: 40px;
  margin-bottom: 0.6em;
}
.section--first .view_graf--h2.view_graf--first,
.section--first .view_graf--h3.view_graf--first,
.section--first .view_graf--h4.view_graf--first {
  margin-top: 0;
  padding-top: 0;
}
.view_graf--h2 + .view_graf--h2 {
  margin-top: -8px;
}
.view_graf--h2 + .view_graf--h3,
.view_graf--h2 + .view_graf--h4 {
  margin-top: -6px;
}
.view_graf--h3 + .view_graf--h2,
.view_graf--h4 + .view_graf--h2 {
  margin-top: 2px;
}
.view_graf--h3 + .view_graf--h4,
.view_graf--h4 + .view_graf--h3 {
  margin-top: -2px;
}
.view_graf--h2 + .postList,
.view_graf--h3 + .postList,
.view_graf--h4 + .postList {
  margin-top: 10px;
}
.view_graf--h2 + .view_graf--p.view_graf--empty,
.view_graf--h3 + .view_graf--p.view_graf--empty,
.view_graf--h4 + .view_graf--p.view_graf--empty {
  margin-bottom: -7px;
  margin-top: -7px;
}
.view_graf--h2 + .view_graf--p.view_graf--empty + .view_graf--h2,
.view_graf--h3 + .view_graf--p.view_graf--empty + .view_graf--h2,
.view_graf--h4 + .view_graf--p.view_graf--empty + .view_graf--h2 {
  margin-top: -5px;
}
.view_graf--h2 + .view_graf--p.view_graf--empty + .view_graf--h3,
.view_graf--h3 + .view_graf--p.view_graf--empty + .view_graf--h3,
.view_graf--h4 + .view_graf--p.view_graf--empty + .view_graf--h3,
.view_graf--h2 + .view_graf--p.view_graf--empty + .view_graf--h4,
.view_graf--h3 + .view_graf--p.view_graf--empty + .view_graf--h4,
.view_graf--h4 + .view_graf--p.view_graf--empty + .view_graf--h4 {
  margin-top: -8px;
}
.view_graf--blockquote,
blockquote {
  font-family: Roboto, "Helvetica", "Arial", sans-serif;
  border-left: 3px solid rgba(0,0,0,0.8);
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0.16px;
  letter-spacing: 0.02rem;
  margin-left: -17px;
  padding-left: 15px;
  margin-bottom: 25px;
  font-size: 1.2em;
  line-height: 1.9em;
  margin-top: 20px;
}
.view_graf--blockquote + .view_graf--blockquote {
  margin-top: -30px;
  padding-top: 30px;
}
.view_graf--pullquote {
  line-height: 1.4;
  text-align: center;
  font-size: 3.2em;
  margin: 48px -160px;
  border: none;
  padding: 0;
  font-family: $dante-font-family-serif;
  letter-spacing: 0.01rem;
  font-weight: 400;
  font-style: italic;
  -webkit-transition: margin 100ms;
  transition: margin 100ms;
}
.view_graf--pre,
.public-DraftStyleDefault-pre {
  background: none repeat scroll 0 0 rgba(0,0,0,0.05);
  font-family: $dante-font-family-mono;
  font-size: 16px;
  margin-bottom: 20px;
  padding: 20px;
  white-space: pre-wrap;
}
.view_graf--pre + .view_graf--pre {
  margin-top: -20px;
}
.draft-view .view_graf--figure {
  box-sizing: border-box;
  clear: both;
  margin-bottom: 30px;
  outline: medium none;
  position: relative;
}
.draft-view .view_graf--figure.is-mediaFocused .view_graf-image,
.draft-view .view_graf--figure.is-mediaFocused iframe {
  box-shadow: 0 0 0 3px #57ad68;
}
.view_graf--mixtapeEmbed a {
  text-decoration: none;
}
.view_graf--mixtapeEmbed.is-mediaFocused {
  box-shadow: 0 0 0 1px #57ad68;
}
.view_graf--mixtapeEmbed .view_graf--media-embed-close {
  position: absolute;
  top: 1px;
  display: inline-block;
  font-size: 2em;
  width: 20px;
  right: 10px;
  text-shadow: 0px 0px 0px #fff;
}
.view_graf--h4 + .view_graf--figure,
.view_graf--h3 + .view_graf--figure,
.view_graf--h2 + .view_graf--figure {
  margin-top: 15px;
}
.view_graf--first {
  margin-top: 0;
  padding-top: 0;
}
/*.view_graf--empty {
  margin-bottom: -7px;
  margin-top: -7px;
}*/
p[data-align="center"],
.view_graf--h2[data-align="center"],
.view_graf--h3[data-align="center"],
.view_graf--h4[data-align="center"],
.view_graf--blockquote[data-align="center"] {
  text-align: center;
}
.markup--anchor,
.view_graf--sectionCaption {
  cursor: text;
}
.markup--anchor {
  text-decoration: underline;
  color: inherit;
}
@media (max-width: 500px) {
  .iraf--h2 {
    font-size: 2.6em;
  }
  .view_graf--h3 {
    font-size: 1.6em;
  }
  .view_graf--h4 {
    font-size: 1.4em;
  }
}
.view_graf--divider span {
  text-align: center;
  width: 100%;
  display: block;
}
.view_graf--divider span:before {
  line-height: 1;
  user-select: none;
  font-weight: 400;
  font-size: 25px;
  letter-spacing: 18px;
  content: "...";
  display: inline-block;
  margin-left: 0.6em;
  position: relative;
  color: #757575;
  top: -3px;
}

.view_imageCaption {
  top: 0;
  text-align: center;
  margin-top: 0;
  font-family: Roboto, "Helvetica", "Arial", sans-serif;
  letter-spacing: 0;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.4;
  color: rgba(0,0,0,0.6);
  outline: 0;
  z-index: 300;
  margin-top: 10px;
  position: relative;
}
.danteDefaultPlaceholder {
  display: none;
}
@media (max-width: 1200px) {
  .view_imageCaption,
  .postField--outsetCenterImage > .view_imageCaption {
    position: relative;
    width: 100%;
    text-align: center;
    left: 0;
    margin-top: 10px;
  }
}
figure.view_graf--layoutOutsetLeft .view_imageCaption,
figure.view_graf--layoutOutsetLeft .postField--outsetCenterImage > .view_imageCaption {
  position: relative;
  width: 100%;
  text-align: center;
  left: 0;
  margin-top: 10px;
}
figure.is-defaultValue .view_imageCaption,
.view_graf--sectionCaption.is-defaultValue {
  display: none;
}
.view_graf--figure.is-mediaFocused .view_imageCaption,
.view_graf--figure.is-defaultValue.is-selected .view_imageCaption,
section.is-mediaFocused .view_graf--sectionCaption,
.view_graf--sectionCaption.is-defaultValue.is-selected {
  display: block;
}

.aspectRatioPlaceholder {
  margin: 0 auto;
  position: relative;
  width: 100%;
}
.is-postEditMode .view_graf-image:before,
.is-postEditMode .iframeContainer:before {
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 500;
}
.aspectRatioPlaceholder .view_graf-image,
.aspectRatioPlaceholder .view_graf-imageAnchor {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.view_graf-image,
.view_graf-imageAnchor,
.iframeContainer > iframe,
.iframeContainer {
  box-sizing: border-box;
  display: block;
  margin: auto;
  max-width: 100%;
}

div[contenteditable="false"] a.markup--anchor {
  cursor: pointer;
}
figcaption .public-DraftStyleDefault-block {
  text-align: center;
}
.aspectRatioPlaceholder .image-upoader-loader {
  position: absolute;
  bottom: 0px;
  left: 0%;
  background-color: #fff;
  width: 100%;
/* height: 3px; */
  text-align: center;
  top: 0px;
  vertical-align: text-bottom;
  opacity: 0.7;
}
.aspectRatioPlaceholder .image-upoader-loader p {
  line-height: 5px;
/* font-weight: 700; */
/* text-transform: uppercase; */
  font-size: 14px;
  margin-top: 49%;
}
a[rel=token],
.markup--query {
  color: #00ab6b;
  text-decoration: none;
  background-image: none !important;
}
.typeahead--mention {
  padding-top: 10px;
}
.typeahead .popover-inner {
  padding: 0;
  overflow: hidden;
  min-width: 100px;
}
.typeahead .typeahead-item:first-child {
  padding-top: 7px;
}
.typeahead--mention .typeahead-item {
  padding: 6px 15px;
  font-size: 13px;
}
.typeahead-item.is-active,
.typeahead-item:hover {
  background: #02b875;
  color: #fff;
}
.typeahead .typeahead-item {
  cursor: pointer;
  padding: 5px 10px;
  line-height: 2;
  font-size: 12px;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.typeahead .dante-avatar {
  margin: 0 6px 0 -2px;
}
.avatar-image--icon {
  width: 32px;
  height: 32px;
}
.avatar-image {
  display: inline-block;
  vertical-align: middle;
  border-radius: 100%;
}
.typeahead-item .avatar-image {
  width: 32px;
  height: 32px;
}
.typeahead--mention .popover-arrow {
  display: none;
}
.markup--user {
  color: #00ab6b;
  text-decoration: none;
}
.popover--card .popover-arrow {
  top: -14px;
}
.popover--card .popover--bottom .popover-arrow:after {
  transform: rotate(45deg) translate(6px, 6px);
  box-shadow: -1px -1px 1px -1px rgba(0,0,0,0.44);
}
.popover--card .popover-arrow:after {
  content: '';
  display: block;
  width: 14px;
  height: 14px;
  background: #fff;
}
.popover--animated.is-active {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 0.2s 0s;
}
.popover.is-active {
  visibility: visible;
}
.popover--animated {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.2s, opacity 0.2s 0s;
}
.popoverCard {
  text-align: left;
}
.popoverCard-meta {
  width: 192px;
}
.u-floatLeft {
  float: left !important;
}
.u-floatRight {
  float: right !important;
}
.popoverCard-title {
  font-size: 18px;
  margin-bottom: 5px;
  margin-top: 0px;
}
.link {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
.popoverCard-description {
  overflow: hidden;
  color: rgba(0,0,0,0.44);
}
.u-clearfix:after {
  clear: both;
}
.u-clearfix:after,
.u-clearfix:before {
  display: table;
  content: " ";
}
.dante-avatar {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: normal;
}
.avatar-image--small {
  width: 60px;
  height: 60px;
}
.avatar-image {
  display: inline-block;
  vertical-align: middle;
  border-radius: 100%;
}
.popoverCard-actions {
  border-top: solid 1px rgba(0,0,0,0.15);
  margin-top: 10px;
  padding-top: 10px;
}
.popoverCard-stats {
  font-size: 14px;
  line-height: 36px;
}
.popoverCard-stat {
  margin-right: 10px;
  color: rgba(0,0,0,0.44);
}
.popoverCard-count {
  padding-left: 5px;
  color: rgba(0,0,0,0.6);
}
.draft-view .view_graf--layoutOutsetLeft {
  margin-left: -160px;
}
.draft-view .view_graf--layoutFillWidth {
  margin-left: -200px;
  margin-right: -200px;
}
.draft-view .view_graf--layoutOutsetLeft {
  width: 75%;
}
.draft-view .view_graf--layoutInsetLeft,
.draft-view .view_graf--layoutOutsetLeft {
  float: left;
  margin-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.public-DraftEditorPlaceholder-inner {
  font-size: initial;
}

/**
 * @providesModule DraftEditor
 * @permanent
 */
/**
 * We inherit the height of the container by default
 */
.DraftEditor-root,
.DraftEditor-editorContainer,
.public-DraftEditor-content {
  height: inherit;
  text-align: initial;
}
.DraftEditor-root {
  position: relative;
}
/*HACK FOR ISSUE  https://github.com/facebook/draft-js/issues/304 , proposal from @bradbumbalough*/
/**
 * Zero-opacity background used to allow focus in IE. Otherwise, clicks
 * fall through to the placeholder.
 */
.DraftEditor-editorContainer {
  background-color: rgba(255,255,255,0);
/* Repair mysterious missing Safari cursor */
  border-left: 0.1px solid transparent;
  position: relative;
  z-index: 1;
}
.public-DraftEditor-content {
  outline: none;
  white-space: pre-wrap;
}
.public-DraftEditor-block {
  position: relative;
}
.DraftEditor-alignLeft .public-DraftStyleDefault-block {
  text-align: left;
}
.DraftEditor-alignLeft .public-DraftEditorPlaceholder-root {
  left: 0;
  text-align: left;
}
.DraftEditor-alignCenter .public-DraftStyleDefault-block {
  text-align: center;
}
.DraftEditor-alignCenter .public-DraftEditorPlaceholder-root {
  margin: 0 auto;
  text-align: center;
  width: 100%;
}
.DraftEditor-alignRight .public-DraftStyleDefault-block {
  text-align: right;
}
.DraftEditor-alignRight .public-DraftEditorPlaceholder-root {
  right: 0;
  text-align: right;
}
/**
 * @providesModule DraftEditorPlaceholder
 */
.public-DraftEditorPlaceholder-root {
  color: #9197a3;
  position: absolute;
  z-index: 0;
  background-color: #fff;
}
.public-DraftEditorPlaceholder-hasFocus {
  color: #bdc1c9;
}
.DraftEditorPlaceholder-hidden {
  display: none;
}
/**
 * @providesModule DraftStyleDefault
 */
.public-DraftStyleDefault-block {
  position: relative;
  white-space: pre-wrap;
}
/* @noflip */
.public-DraftStyleDefault-ltr {
  direction: ltr;
  text-align: left;
}
/* @noflip */
.public-DraftStyleDefault-rtl {
  direction: rtl;
  text-align: right;
}
/**
 * These rules provide appropriate text direction for counter pseudo-elements.
 */
/* @noflip */
.public-DraftStyleDefault-listLTR {
  direction: ltr;
}
/* @noflip */
.public-DraftStyleDefault-listRTL {
  direction: rtl;
}
/**
 * Default spacing for list container elements. Override with CSS as needed.
 */
.public-DraftStyleDefault-ul,
.public-DraftStyleDefault-ol {
  margin: 16px 0;
  padding: 0;
}
/**
 * Default counters and styles are provided for five levels of nesting.
 * If you require nesting beyond that level, you should use your own CSS
 * classes to do so. If you care about handling RTL languages, the rules you
 * create should look a lot like these.
 */
/* @noflip */
.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
  margin-left: 1.5em;
}
/* @noflip */
.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listRTL {
  margin-right: 1.5em;
}
/* @noflip */
.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listLTR {
  margin-left: 3em;
}
/* @noflip */
.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listRTL {
  margin-right: 3em;
}
/* @noflip */
.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listLTR {
  margin-left: 4.5em;
}
/* @noflip */
.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listRTL {
  margin-right: 4.5em;
}
/* @noflip */
.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listLTR {
  margin-left: 6em;
}
/* @noflip */
.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listRTL {
  margin-right: 6em;
}
/* @noflip */
.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listLTR {
  margin-left: 7.5em;
}
/* @noflip */
.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listRTL {
  margin-right: 7.5em;
}
/**
 * Only use `square` list-style after the first two levels.
 */
.public-DraftStyleDefault-unorderedListItem {
  list-style-type: square;
  position: relative;
}
.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth0 {
  list-style-type: disc;
}
.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth1 {
  list-style-type: circle;
}
/**
 * Ordered list item counters are managed with CSS, since all list nesting is
 * purely visual.
 */
.public-DraftStyleDefault-orderedListItem {
  list-style-type: none;
  position: relative;
}
/* @noflip */
.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
  left: -36px;
  position: absolute;
  text-align: right;
  width: 30px;
}
/* @noflip */
.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listRTL:before {
  position: absolute;
  right: -36px;
  text-align: left;
  width: 30px;
}
/**
 * Counters are reset in JavaScript. If you need different counter styles,
 * override these rules. If you need more nesting, create your own rules to
 * do so.
 */
.public-DraftStyleDefault-orderedListItem:before {
  content: counter(ol0) ". ";
  counter-increment: ol0;
}
.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth1:before {
  content: counter(ol1) ". ";
  counter-increment: ol1;
}
.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth2:before {
  content: counter(ol2) ". ";
  counter-increment: ol2;
}
.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth3:before {
  content: counter(ol3) ". ";
  counter-increment: ol3;
}
.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth4:before {
  content: counter(ol4) ". ";
  counter-increment: ol4;
}
.public-DraftStyleDefault-depth0.public-DraftStyleDefault-reset {
  counter-reset: ol0;
}
.public-DraftStyleDefault-depth1.public-DraftStyleDefault-reset {
  counter-reset: ol1;
}
.public-DraftStyleDefault-depth2.public-DraftStyleDefault-reset {
  counter-reset: ol2;
}
.public-DraftStyleDefault-depth3.public-DraftStyleDefault-reset {
  counter-reset: ol3;
}
.public-DraftStyleDefault-depth4.public-DraftStyleDefault-reset {
  counter-reset: ol4;
}

.draft-view .popover-inner {
  width: auto !important;
  padding: 0 !important;
  background-color: transparent !important;
}
.draft-view {
  font-family: Roboto, sans-serif, "Helvetica", "Arial";
  font-size: 20px;
  font-weight: 400;
  line-height: 1.8;
  color: #4a4a4a;
}
.draft-view .view_graf--layoutOutsetLeft {
  width: 50%;
}
.draft-view h1 {
  font-size: 40px;
  color: rgba(0,0,0,0.74);
  font-family: Roboto, sans-serif, "Helvetica", "Arial";
  font-weight: 700;
}
.draft-view h2 {
  font-size: 26px;
  color: rgba(0,0,0,0.74);
}
@media screen and (max-width: 767px) {
  .draft-view {
    font-size: 18px;
  }
  .draft-view .view_graf--layoutOutsetLeft {
    width: 100%;
    float: none;
    margin: 0 auto;
  }
  .draft-view .view_graf--layoutFillWidth {
    margin-left: 0px;
    margin-right: 0px;
  }
  .draft-view .view_graf--layoutFillWidth > figure {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.question-type-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  width: 25px;
  height: 25px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.question-type-icon svg {
  fill: #fff;
}

.product-gallery-article {
  font-size: 15px;
  margin-top: 180px !important;
  margin-bottom: 15px !important;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
}
.product-gallery-article .draft-view {
  font-size: 16px !important;
  line-height: 24px !important;
}
.product-gallery-article .view_graf--h3 {
  font-size: 25px !important;
}
@media screen and (max-width: 767px) {
  .product-gallery-article .draft-view {
    font-size: 15px !important;
    line-height: 25px;
  }
  .product-gallery-article {
    margin-left: -5px !important;
    margin-right: -5px !important;
  }
}

.item-gallery-widget .box-shadow.right {
  right: -61px;
  -webkit-box-shadow: -20px 0 30px -20px rgba(0,0,0,0.3);
  box-shadow: -20px 0 30px -20px rgba(0,0,0,0.3);
  position: absolute;
  z-index: 1;
  top: 20px;
  bottom: 0;
  display: none;
  width: 60px;
  display: block;
  height: 100%;
}
.item-gallery-widget .box-shadow.left {
  left: -61px;
  -webkit-box-shadow: 20px 0 30px -20px rgba(0,0,0,0.3);
  box-shadow: 20px 0 30px -20px rgba(0,0,0,0.3);
  position: absolute;
  z-index: 1;
  top: 20px;
  bottom: 0;
  display: none;
  width: 60px;
  display: block;
  height: 100%;
}



#content-preview .item-gallery-header {
  margin-bottom: 5px !important;
}
#content-preview .item-gallery-header .label {
  font-weight: 700;
  font-size: 22px;
  color: rgba(0,0,0,0.74);
}

.loading-box {
  width: 100%;
  margin-top: 100px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}


