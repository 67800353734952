

.product-grid-tmall .item {
    width: 20%
    min-width: 20%
    padding 8px
}

.product-grid-tmall.with-filter .item {
    width: 25%
    min-width: 25%
}

@media only screen and (max-width: 1000px) {
  .product-grid-tmall .item {
    width: 25%
    min-width: 25%
  }
  .product-grid-tmall.with-filter .item {
    width: 33.33333%
    min-width: 33.33333%
  }
}


.product-mobile-grid {
    margin-left: 5px;
    margin-right: 5px;
    min-height: 70px;
    transition: .3s;
    opacity: 1;
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
}

.product-mobile-grid .item {
    width: 50%
}


/*

.product-grid
  display: grid;
  grid-template-columns: 200px 200px 200px 200px 200px 200px
  justify-content: space-between;
  row-gap: 25px;
  column-gap: 25px;




@media only screen and (max-width: 1390px) {
  .product-grid {
    grid-template-columns: 200px 200px 200px 200px 200px
  }
}

@media only screen and (max-width: 1130px) {
  .product-grid {
    grid-template-columns: 200px 200px 200px 200px 200px
  }
}

@media only screen and (max-width: 910px) {
  .product-grid {
    grid-template-columns: 200px 200px 200px
  }
}



@media only screen and (max-width: 768px) {
  .product-grid {
    grid-template-columns: 100%
  }
  .product-item .name {
    font-size 16px !important
  }
}

*/
