


.category-dropdown-widget {
    display: block;
    top: 45px;
    left: 0;
    //box-shadow: 0 3px 5px 0 rgba(51,51,51,.3);
}

.category-dropdown-widget .subcategory {
    display: none;
    left: 238px;
}

.category-dropdown-widget .category:hover .subcategory {
    display: flex;
    top: -21px
    min-height: calc(100% + 42px)
}


.category-dropdown-cancel-area {
    z-index: -1;
    position: fixed;
    right: 0px;
    bottom: 0px;
    top: 0px;
    left: 0px;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
    touch-action: none;
}
