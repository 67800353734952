

.product-gallery-category {
    display flex
    flex-wrap wrap
    font-size 15px
    margin-bottom 15px
    padding-left 10px
    padding-right 10px
    padding-top 15px
    padding-bottom 7px
    color #4a4a4a
}


.product-gallery-category .item {
    margin-right: 8px;
    margin-bottom 8px
    padding: 2px 10px;
    background: #eee;
    border-radius: 3px;
    text-decoration: none;
    font-weight: 400
    color: #707070;
    font-size 13px
    white-space: nowrap;
    opacity 0.8
    cursor pointer

}

.product-gallery-category .item:hover {
    opacity 1
}

.product-gallery-category .item.selected {
    background: rgb(78, 87, 156);
    color: #ececec;
}


//mobile

.product-gallery-category-mobile
    margin-left -15px
    margin-right -15px
    margin-top 0px
    margin-bottom 18px
    overflow-x auto

.product-gallery-category-mobile > div {
    padding-left 15px
}

.product-gallery-category-mobile .item {
    margin-right: 8px;
    padding: 10px 10px;
    background: white;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 400
    color: #707070;
    font-size 13px
    white-space: nowrap;

}

.product-gallery-category-mobile .item.selected {
    background: #8890cc
    color: white
}

.category-menu-block {
    height 55px
    border-bottom 1px solid #f7f7f7
    color rgba(0,0,0,.54) //#8492a6
    //font-weight: 300
}

.category-menu-block .item-container {
    //height 100%
    //margin-left 10px
    //margin-right 10px
}


.category-menu-block .item {
    height 52px
    margin-left 10px
    margin-right 10px
    font-size 15px
    //padding-top 14px
    //padding-bottom 14px
    display flex
    align-items center
    cursor pointer
    border-top 2px solid transparent
    border-bottom 2px solid transparent
}

.category-menu-block .item-container:hover .item {
    border-bottom 2px solid rgb(113, 119, 167)
}


// menu dropdown


.category-menu-block .subitem {
    display: none;
    left: -6px;
    top: 52px
    padding-top 5px !important
}

.category-menu-block .subitem > div {
    box-shadow: 0 3px 5px 0 rgba(51,51,51,.3);
}

.category-menu-block .item-container:hover .subitem {
    display: flex;
}

//

@media screen and (max-width: 767px) {
    .product-gallery-category {
         margin-left -5px !important
         margin-right -5px !important
         margin-top 0px !important
         margin-bottom 12px !important
    }
}
