



.filter-mobile-widget .action
    position: fixed;
    bottom: 0;
    left: 0;
    width: 80%;
    z-index: 100;
    //box-shadow: 0 4px 16px 0 rgba(0,26,52,.1);
    padding: 8px 16px;


.filter-mobile-widget .MuiButton-containedPrimary {
    background-color #606fbb
}
