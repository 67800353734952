


.filter-mobile-widget .item-list
    margin-top 0
    margin-bottom 0
    padding 0

.filter-mobile-widget .item
    min-height 44px
    padding-left 0px
    padding-right 0px
    padding-top 0px
    padding-bottom 0px
    border-bottom: 1px solid #eee;
    color rgba(0,0,0,.54)

.filter-mobile-widget .item-list.child .item {
    color rgba(0,0,0,.84)
}

.filter-mobile-widget .item .MuiListItemIcon-root {
    min-width 50px
}
