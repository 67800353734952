


.filter-mobile-widget .MuiDrawer-paper {
    width: 80%
    font-size 16px
}


.filter-mobile-widget .facet-filter-widget .MuiSvgIcon-root {
    width: 24px;
    height: 24px;
    opacity: 0.6;
}


.filter-mobile-widget .link {
    font-size 14px !important
}
