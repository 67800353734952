.view_imageCaption {
  top: 0;
  text-align: center;
  margin-top: 0;
  font-family: Roboto, "Helvetica", "Arial", sans-serif
  letter-spacing: 0;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.4;
  color: rgba(0,0,0,0.6);
  outline: 0;
  z-index: 300;
  margin-top: 10px;
  position:relative;
}

.danteDefaultPlaceholder{
  display:none;
}

@media (max-width: 1200px) {
  .view_imageCaption,
  .postField--outsetCenterImage > .view_imageCaption {
    position: relative;
    width: 100%;
    text-align: center;
    left: 0;
    margin-top: 10px;
  }
}

figure.view_graf--layoutOutsetLeft {
  .view_imageCaption,
  .postField--outsetCenterImage > .view_imageCaption {
    position: relative;
    width: 100%;
    text-align: center;
    left: 0;
    margin-top: 10px;
  }
}

figure.is-defaultValue .view_imageCaption,
.view_graf--sectionCaption.is-defaultValue {
  display: none;
}

.view_graf--figure.is-mediaFocused .view_imageCaption,
.view_graf--figure.is-defaultValue.is-selected .view_imageCaption,
section.is-mediaFocused .view_graf--sectionCaption,
.view_graf--sectionCaption.is-defaultValue.is-selected {
  display: block;
}
