

.searchbar-widget
    transition background-color 250ms cubic-bezier(0.4, 0.0, 0.2, 1) 0ms

.searchbar-widget:hover
  background-color rgba(0, 0, 0, 0.06) !important


.searchbar-widget .MuiInputBase-input {
    padding 0
}








.autocomplete-dropdown-cancel-area {
    z-index: -1;
    position: fixed;
    right: 0px;
    bottom: 0px;
    top: 0px;
    left: 0px;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
    touch-action: none;
}
