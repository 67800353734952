
.product-gallery-article {
    font-size 15px
    margin-top 180px !important
    margin-bottom 15px !important
    padding-left 20px
    padding-right 20px
    padding-top 30px
    padding-bottom 30px
}

.product-gallery-article .draft-view {
     font-size: 16px !important
     line-height: 24px !important
}

.product-gallery-article .view_graf--h3 {
    font-size 25px !important
}

@media screen and (max-width: 767px) {
    .product-gallery-article .draft-view {
         font-size: 15px !important
         line-height 25px
    }
    .product-gallery-article {
         margin-left -5px !important
         margin-right -5px !important
    }
}
