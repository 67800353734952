


.ant-pagination {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0,0,0,0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum';
}

.ant-pagination .item {
    display: inline-block;
    min-width: 32px;
    height: 32px;
    margin-right: 8px;
    font-family: Arial;
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    outline: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


.ant-pagination .item a {
    display: block;
    padding: 0 6px;
    color: rgba(0,0,0,0.65);
    -webkit-transition: none;
    transition: none;
}

.ant-pagination .item.selected {
    font-weight: 500;
    background: #fff;
    border-color: #3f51b5;
    color: #3f51b5;
}

.ant-pagination .item:focus, .ant-pagination .item:hover {
    border-color: #3f51b5;
    color: #3f51b5;
    -webkit-transition: all .3s;
    transition: all .3s;
}
